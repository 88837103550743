import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Hero from "../components/hero"
import SEO from "../components/seo"
import styled from "styled-components"

const Container = styled.div`
  position: relative;
  padding: 30px;
  padding: 20px;
  @media (min-width: 767px) {
    padding: 40px;
  }
`
const TitleCont = styled.div`
  /* background-color: white; */
  width: auto;
  display: inline-block;
`
const Title = styled.h1`
  font-family: ${props => props.theme.roboto};
  /* color: hsla(86, 93%, 11%, 1); */
  color: white;
  font-size: 3em;
  @media (min-width: 767px) {
    font-size: 5em;
  }
`
const Subtitle = styled.h3`
  font-family: ${props => props.theme.roboto};
  /* color: hsla(96, 93%, 41%, 1); */
  color: white;
  font-size: 1.5em;
  @media (min-width: 767px) {
    font-size: 2em;
  }
`

const ContactBox = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;
`
const Input = styled.input`
  appearance: none;
  margin: 10px 0;
  padding: 10px;
  border: none;
  border-radius: 2px;
  background-color: hsla(120, 1%, 93%, 1);
  color: hsla(82, 15%, 19%, 1);
  font-size: 1.3em;
  font-family: ${props => props.theme.roboto};
`
const MessageBox = styled.textarea`
  background-color: hsla(120, 1%, 93%, 1);
  border: none;
  border-radius: 2px;
  color: hsla(82, 15%, 19%, 1);
  font-family: ${props => props.theme.roboto};
  min-height: 120px;
  font-size: 1.3em;
  padding: 10px;
  margin: 10px 0;
`
const SubmitButton = styled.button`
  /* max-width: 200px; */
  font-size: 1.2em;
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
  font-family: ${props => props.theme.roboto};
  @media (min-width: 767px) {
    max-width: 200px;
  }
`
const SectionTitle = styled(Subtitle)`
  margin: 0;
  font-size: 1.2em;
  @media (min-width: 767px) {
    font-size: 1.7em;
  }
`

const IndexPage = () => (
  <Layout>
    <Container>
      <Hero />
      <SEO title="Home" />
      <TitleCont>
        <Title>Lyndsey Antanitis</Title>
        <Subtitle>Sustainability consulting for small businesses</Subtitle>
      </TitleCont>
      <ContactBox>
        {/* <SectionTitle>Contact Lyndsey</SectionTitle> */}
        <Input placeholder="Name"></Input>
        <Input placeholder="Email"></Input>
        <MessageBox placeholder="Message"></MessageBox>
        <SubmitButton>Send Message</SubmitButton>
      </ContactBox>
    </Container>
  </Layout>
)

export default IndexPage
